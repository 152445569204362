import { io, Socket } from "socket.io-client";
import { Dispatch } from "@reduxjs/toolkit";
import * as socketActions from "../redux/slices/OrderSocketState";
import { OrderFromDB } from "../redux/slices/types/OrderAndSocketState";
import { updateOrders } from "../redux/slices/OrderSocketState";
import { getProdBaseURL } from "./xhr";
import { get } from "lockr";

export type SocketEvents = {
  connect: (dispatch: Dispatch) => void;
  disconnect: (dispatch: Dispatch) => void;
  connectionAck: (message: string) => void;
  simulate: (test: string) => void;
  newOrder: (order: OrderFromDB) => void;
};

export const initiateSocketIo = async (
  hasOrderFeature = false,
  dispatch: Dispatch<any>
): Promise<boolean | void> => {
  if (!hasOrderFeature) return false;
  const token = get("token");
  let url = "http://localhost:3500";
  if (process.env.NODE_ENV === "production") url = getProdBaseURL();

  if (url) {
    const socket = io(url, {
      withCredentials: true,
      auth: { token },
    }) as Socket<SocketEvents>;
    if (window && socket) {
      window.socket = socket;
      dispatch(socketActions.setSocketConnect({ isActive: socket.connected }));
    }
  }
};

const onConnect = (dispatch: Dispatch): void => {
  dispatch(
    socketActions.setSocketConnect({ isActive: !!window.socket?.connected })
  );
};

const onDisconnect = (dispatch: Dispatch) => {
  dispatch(socketActions.setSocketDisconnect({ isActive: false }));
};

const onNewOrder = (order: OrderFromDB, dispatch: Dispatch<any>) => {
  console.log("new order ==>", order);
  dispatch(updateOrders(order));
};

export const handleSocketEvents = (
  socket: Socket<SocketEvents>,
  dispatch: Dispatch<any>
) => {
  if (!socket) return;
  socket.on("connect", () => onConnect(dispatch));
  socket.on("disconnect", () => onDisconnect(dispatch));
  socket.on("newOrder", (order) => onNewOrder(order, dispatch));
};

export const cleanUpListeners = (
  socket: Socket<SocketEvents>,
  dispatch: Dispatch
) => {
  if (!socket) return;
  socket.off("connect", () => onConnect(dispatch));
  socket.off("disconnect", () => onDisconnect(dispatch));
};
