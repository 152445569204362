import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderFromDB, OrderAndSocketState } from "./types/OrderAndSocketState";

const initialState: OrderAndSocketState = {
  isSocketActive: false,
  orders: [],
};

export const OrderAndSocket = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSocketConnect: (state, action: PayloadAction<{ isActive: boolean }>) => {
      return {
        ...state,
        isSocketActive: action.payload.isActive,
      };
    },
    setSocketDisconnect: (
      state,
      action: PayloadAction<{ isActive: boolean }>
    ) => {
      return {
        ...state,
        isSocketActive: action.payload.isActive,
      };
    },
    setOrders: (state, action: PayloadAction<OrderFromDB[]>) => {
      return { ...state, orders: action.payload };
    },
    updateOrders: (state, action: PayloadAction<OrderFromDB>) => {
      const existingOrders = state.orders as OrderFromDB[];

      const orderUpdated = existingOrders.some(
        (order) => order.orderNumber === action.payload.orderNumber
      );

      if (orderUpdated) return;

      const updatedOrders = [action.payload, ...existingOrders];
      console.log("updatedOrders", updatedOrders);
      return { ...state, orders: updatedOrders };
    },
    updateOrderStatus: (state, action: PayloadAction<OrderFromDB>) => {
      const updatedOrder = action.payload;
      const updatedOrders = state.orders.map((order) =>
        order.id === updatedOrder.id ? updatedOrder : order
      );

      return { ...state, orders: updatedOrders };
    },
  },
});

export const {
  setSocketConnect,
  setSocketDisconnect,
  setOrders,
  updateOrders,
  updateOrderStatus,
} = OrderAndSocket.actions;

export default OrderAndSocket.reducer;
