export interface ProductInterface {
  category?: { name: string };
  categoryId: string;
  descriptions: ProductFormText;
  id: string;
  names: ProductFormText;
  photo: string | Media;
  productPhoto: {
    id: string;
    publicId: string;
    secureUrl: string;
    mediaSource?: MediaSource;
  };
  price: number;
  mediaSource: number;
  prices: ProductFormPrice;
  inStock: boolean;
  editMode?: boolean;
  sales?: [
    | {
        bartender: string;
        dayStartStock: number;
        dayEndStock: number;
        sales: number;
      }
    | any
  ];
}
export interface Category {
  createdAt: string;
  id: string;
  drinks?: ProductInterface[];
  food?: ProductInterface[];
  name: string;
  updatedAt: string;
  userId: string;
}
export interface Categories {
  categories: Array<Category>;
  productType: ProductTypes;
}
export type ProductTypes = "Drink" | "Food"; //todo: replace all usage with ProductType enum in product.tsx
export interface ModalSubComponent {
  activeProduct: ProductInterface;
  modalMode: number;
  productType: number;
}

export type XHR_Error = {
  error: { message: string };
  originalData: Record<string, any>;
};
export interface ProductResponse {
  success: boolean;
  addedRecord?: Record<string, any>;
  products?: ProductInterface[];
  product?: any;
  error?: any;
}

export const FEEDBACK_ALERTS = {
  PRODUCT_ADDED: "Product Added",
  PRODUCT_UPDATED: "Product Updated",
  PRODUCT_DELETED: "Product Deleted",
  CREATE_PRODUCT: {
    STEP_REQUIRED: "Please complete the current step",
  },
  LARGE_FILE_SIZE: "Please upload a photo not exceeding a 500kb file size.",
  INVALID_FILE: "Please upload a jpg, jpeg, png or svg file.",
  UNKNOWN_ERR: "The process could not be completed.",
};

export interface ProductFormData {
  name: string;
  description: string;
  categoryId: string; // categoryID
  price: number;
  photo: string;
  stockCount?: number;
}

export interface BusinessInfo {
  address?: string;
  brief?: string;
  contacts?: string[];
  activities?: string[];
}

export interface Currency {
  prefix: string;
  name: string;
}

export interface Language {
  iso: string;
  name: string;
}

export interface Translation {
  iso: string;
  name: string;
  translations: { [key: string]: string };
}

export interface Segment {
  name: string;
}

export interface User {
  currency: {
    prefix: string;
    name: string;
  };
  defaultLanguage: string;
  legalName: string;
  canPlaceOrder: boolean;
  languages: Language[];
  segments: Segment[];
  hasSegments: boolean;
  username: string;
  userLanguages: Translation[];
  activeLanguage: string;
  membershipDetails: MembershipDetails;
  address?: string;
  brief?: string;
  contacts?: string[];
  activities?: string[];
  isVerified: boolean;
}
export interface ProductFormText {
  [key: string]: string;
}

export interface ProductFormPrice {
  [key: string]: number;
}

export interface NewProduct {
  descriptions: ProductFormText;
  inStock: boolean;
  names: ProductFormText;
  prices?: ProductFormPrice;
  categoryId: string;
  photo: string | null; // New photo. Could be null in case of product update when photo has not changed
  id: string;
  mediaSource: number;
  price?: number;
  productPhoto?: {
    id: string;
    publicId: string;
    secureUrl: string;
    mediaSource?: MediaSource;
  };
}

export interface CategoryUpdateData {
  id: string;
  name: string;
  itemType: ProductTypes;
}

export enum StaffRole {
  ATTENDANT,
  SECRETARY,
  MANAGER,
  KITCHEN,
  ACCOUNTANT,
}

export interface Staff {
  name: string;
  staffId: number;
  role: number;
  permissions: string[];
}

export interface StaffFormData {
  name: string;
  email: string;
  password: string;
  role: number;
  permissions: string[];
}

export interface PasswordUpdateData {
  oldPassword: string;
  newPassword: string;
}

//=============
export enum ProductAddSteps {
  SelectProduct,
  SelectCategory,
  FillForm,
  Feedback,
}

export enum MediaSource {
  Upload = 1,
  MediaPicker,
}

export interface Media {
  id: string;
  photo: string;
  tags: string[];
}

export enum MembershipTier {
  PREMIUM = 1,
  BASIC = 2,
  FREE = 3,
}

export interface MembershipLimits {
  staff: number;
  product: number;
  segment: number;
  order: boolean;
}

export interface MembershipDetails {
  id: string;
  name: string;
  tier: MembershipTier;
  price: number;
  description: string[];
  limits: MembershipLimits;
}
