import styled from "styled-components";
import { isNightMode } from "../../../helpers/generic";
import { Button } from "../../styled/Generic";
import { palette } from "../../../assets/color";

export const Container = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: linear-gradient(to bottom right, #c34f5a, #000000e3);
`;
export const FeedbackWrapper = styled.div`
  width: 100%;
  display: inherit;
  justify-content: center;
`;
export const FormContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
export const Form = styled.form`
  max-width: 397px;
  width: 100%;
  min-height: 350px;
  text-align: center;
  padding: 10px 30px;
  background-color: ${isNightMode() ? "#444444" : `${palette.body}`};
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    min-height: 100vh;
    width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputField = styled.input`
  width: 100%;
  height: 40px;
  font-size: 15px;
  color: #313131;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  padding: 5px 30px 5px 15px;
  /* margin-bottom: 15px; */
  transition: border-color 0.3s;

  &:focus {
    border-color: #6e151e;
    box-shadow: 0 0 5px rgba(110, 21, 30, 0.5);
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #000000d7;
  pointer-events: none;
`;

export const SubmitButton = styled(Button)`
  height: 45px;
  background-color: #6e151e;
  width: 100%;
  border: none;
  margin-bottom: 30px;
  border-radius: 5px;
  font-weight: 500;

  &:hover {
    background-color: #6e151ec0;
  }
`;

export const Line = styled.div`
  height: 1px;
  background-color: #ebebeb;
  width: 100%;
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0px 10px;

  a {
    text-decoration: none;
    color: #4286f4;
    font-size: 14px;
    transition: color 0.3s ease, transform 0.3s ease;

    &:hover {
      color: #6e151ec0;
      transform: translateY(-1px);
    }
  }
`;
