import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  height: 75vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Loader = () => {
  const loader = require("../../assets/loader.gif");
  return (
    <LoaderWrapper>
      <Box
        component="img"
        src={loader}
        alt="loading"
        sx={{
          width: 100,
          mb: 1,
        }}
      />
      <Typography variant="body1" sx={{ mb: 2 }}>
        Loading your dashboard
      </Typography>
    </LoaderWrapper>
  );
};

export default Loader;
