import { createBrowserRouter } from "react-router-dom";
import Login from "./components/auth/Login";
import NotFound from "./Not-Found";
import { ProSidebarProvider } from "react-pro-sidebar";
import App from "./App";
import { store } from "./redux/store";
import {
  getAllCategories,
  getDrink,
  getDrinks,
  getFood,
  getFoodProducts,
  getStaffs,
} from "./helpers/xhr";
import Modal from "./components/modal/Modal";
import { MODAL } from "./components/modal/helpers/constants";
import { ErrorBoundary } from "./components/extras/RouteError";
import React, { lazy } from "react";
import { UpgradeProvider } from "./components/upgrade/UpgradeContext";
import { ProductType } from "./components/product";
import { validateAuth } from "./components/auth/helper";
import Loader from "./components/extras/Loader";
import EmailVerification from "./components/auth/EmailVerification";
import { get } from "lockr";

const Dashboard = lazy(() =>
  import("./pages/dashboard").then((module) => ({ default: module.Dashboard }))
);
const NewProduct = lazy(() =>
  import("./pages/new-product").then((module) => ({
    default: module.NewProduct,
  }))
);
const DrinkPage = lazy(() =>
  import("./pages/drink").then((module) => ({ default: module.DrinkPage }))
);
const Food = lazy(() =>
  import("./pages/food").then((module) => ({ default: module.Food }))
);
const Order = lazy(() =>
  import("./pages/order").then((module) => ({ default: module.Order }))
);
const StaffManagement = lazy(() =>
  import("./pages/staff-management").then((module) => ({
    default: module.StaffManagement,
  }))
);
const Settings = lazy(() =>
  import("./pages/settings").then((module) => ({ default: module.Settings }))
);
const user = get("user");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <NotFound />,
  },
  {
    path: "app",
    element: (
      <UpgradeProvider>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
      </UpgradeProvider>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <React.Suspense fallback={<Loader />}>
            <Dashboard />
          </React.Suspense>
        ),
        loader: () => {
          if (!user?.isVerified) {
            window.location.href = "/app/email-verification";
            return null;
          }
          const { drinks, food } = store.getState().Product;
          if (!drinks.length) {
            getDrinks("Loader", store.dispatch);
          }
          if (!food.length) {
            getFoodProducts("Loader", store.dispatch);
          }
          return { drinks, food };
        },
      },
      {
        path: "email-verification",
        element: (
          <React.Suspense fallback={<Loader />}>
            <EmailVerification />
          </React.Suspense>
        ),
      },
      {
        path: "order",
        element: (
          <React.Suspense fallback={<Loader />}>
            <Order />
          </React.Suspense>
        ),
        loader: async () => {
          await validateAuth(store.dispatch);
          return null;
        },
      },
      {
        path: "drinks",
        element: (
          <React.Suspense fallback={<Loader />}>
            <DrinkPage />
          </React.Suspense>
        ),
        loader: async () => {
          await validateAuth(store.dispatch);
          const productState = store.getState().Product;
          const drinks = productState.drinks;
          const categoriesExist = productState.drinkCategories.length;
          if (!drinks.length)
            return getDrinks("Loader", store.dispatch, !!categoriesExist);
          return drinks;
        },
        children: [
          {
            path: ":drinkId",
            element: (
              <Modal
                show={true}
                productType={ProductType.Drink}
                modalModeInit={MODAL.VIEW}
              />
            ),
            loader: ({ params }) => {
              return getDrink(params.drinkId, store.dispatch);
            },
          },
        ],
      },
      {
        path: "food",
        element: (
          <React.Suspense fallback={<Loader />}>
            <Food />
          </React.Suspense>
        ),
        loader: async () => {
          await validateAuth(store.dispatch);
          const productState = store.getState().Product;
          const categoriesExist = productState.foodCategories.length;
          const food = productState.food;
          if (!food.length)
            return getFoodProducts("Loader", store.dispatch, !!categoriesExist);
          return food;
        },
        children: [
          {
            path: ":foodId",
            element: (
              <Modal
                show={true}
                productType={ProductType.Food}
                modalModeInit={MODAL.VIEW}
              />
            ),
            loader: ({ params }) => {
              return getFood(params.foodId, store.dispatch);
            },
          },
        ],
      },
      {
        path: "add-product",
        element: (
          <React.Suspense fallback={<Loader />}>
            <NewProduct />
          </React.Suspense>
        ),
        loader: async () => {
          await validateAuth(store.dispatch);
          const { drinkCategories, foodCategories } = store.getState().Product;
          if (!(drinkCategories.length && foodCategories.length)) {
            return getAllCategories(store.dispatch);
          }
          return null;
        },
      },
      {
        path: "staff-management",
        element: (
          <React.Suspense fallback={<Loader />}>
            <StaffManagement />
          </React.Suspense>
        ),
        loader: async () => {
          await validateAuth(store.dispatch);
          const { staffs } = store.getState().Staff;
          if (!staffs.length) {
            return getStaffs(store.dispatch);
          }
          return null;
        },
      },
      {
        path: "settings",
        element: (
          <React.Suspense fallback={<Loader />}>
            <Settings />
          </React.Suspense>
        ),
        loader: async () => {
          await validateAuth(store.dispatch);
          return null;
        },
      },
    ],
    errorElement: <ErrorBoundary />,
  },
]);

export default router;
