import React from "react";
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  SxProps,
  Theme,
} from "@mui/material";

interface CustomButtonProps extends MUIButtonProps {
  sx?: SxProps<Theme>;
  background?: string;
  height?: string;
  size?: "small" | "medium" | "large";
  children: React.ReactNode;
  disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  background = "#0e0607",
  size = "medium",
  children,
  height = "40px",
  sx,
  disabled = false,
  ...props
}) => {
  return (
    <MUIButton
      {...props}
      sx={{
        backgroundColor: disabled ? "rgba(128, 128, 128, 0.5)" : background,
        color: "white",
        fontSize: "1rem",
        height,
        width: "100%",
        borderRadius: "16px",
        fontWeight: "500",
        whiteSpace: "nowrap",
        px: 2,
        textTransform: "none",
        "&:hover": {
          backgroundColor: disabled
            ? "rgba(128, 128, 128, 0.5)"
            : `${background}ab`,
          cursor: disabled ? "not-allowed" : "pointer",
        },
        ...sx,
      }}
      size={size}
      disabled={disabled}
    >
      {children}
    </MUIButton>
  );
};

export default CustomButton;
