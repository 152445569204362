import React, { useState } from "react";
import * as LoginComponents from "./styledComponents/styledLogin";
import FeedbackAlert from "../extras/Alert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { XHR_STATE } from "../../helpers/xhr";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Typography } from "@mui/material";
import { resetPasswordHandler } from "./helper";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { feedback, xhr } = useSelector((state: RootState) => state.App);
  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    resetPasswordHandler(e, email, dispatch);
  };
  return (
    <LoginComponents.Container>
      <LoginComponents.FeedbackWrapper>
        <FeedbackAlert
          show={feedback.show}
          message={feedback.message}
          type={feedback.type}
          timeout={2000}
        />
      </LoginComponents.FeedbackWrapper>
      <LoginComponents.Form onSubmit={handleSubmit}>
        <LoginComponents.FormContentWrapper>
          <Typography variant="h4" py={2}>
            Forgot Password
          </Typography>
          <Typography variant="body1" mb={4} sx={{ color: "#313131d7" }}>
            Enter your email registered with us and we will send you a password
            reset information.
          </Typography>

          <Box component="div" width="100%" mb={4}>
            <LoginComponents.InputWrapper>
              <LoginComponents.InputField
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                disabled={xhr === XHR_STATE.REQUESTING}
                required={true}
              />

              <LoginComponents.Icon>
                <EmailIcon fontSize="small" />
              </LoginComponents.Icon>
            </LoginComponents.InputWrapper>
          </Box>

          <Box component="div" width="70%">
            <LoginComponents.SubmitButton
              type="submit"
              disabled={xhr === XHR_STATE.REQUESTING}
            >
              {xhr === XHR_STATE.REQUESTING ? "Sending..." : "Reset Password"}
            </LoginComponents.SubmitButton>
          </Box>
        </LoginComponents.FormContentWrapper>
      </LoginComponents.Form>
    </LoginComponents.Container>
  );
};

export default ResetPassword;
