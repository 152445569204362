import { FormEvent } from "react";
import { xhr, XHR_STATE, xhrHeaders } from "../../helpers/xhr";
import { Dispatch } from "@reduxjs/toolkit";
import { updateFeedback, updateXHR } from "../../redux/slices/AppState";
import {
  setUserState,
  updateIsAuthenticated,
  updateIsVerified,
  USER_STATE,
} from "../../redux/slices/Auth";
import { FEEDBACK_VARIANT } from "../extras/Alert";
import { User } from "../../types/common.types";
import { get, rm, set } from "lockr";
import { initiateSocketIo } from "../../helpers/socketEvents";
import { ROUTES } from "../modal/helpers/constants";

export type Credentials = {
  username: string;
  password: string;
};
export const loginSubmitHandler = (
  e: FormEvent<HTMLFormElement>,
  data: Credentials,
  dispatch: Dispatch<any>
) => {
  e.preventDefault();
  dispatch(updateXHR({ xhr: XHR_STATE.REQUESTING }));
  xhr
    .post(
      "/auth/user/login/password",
      {
        ...data,
      },
      { headers: xhrHeaders }
    )
    .then(async (response) => {
      if (response.data.success) {
        const token = response.data.token;

        set("token", token);

        const rawUser = response.data.user;
        if (rawUser && Object.keys(rawUser).length) {
          const user: User = {
            currency: rawUser.currency,
            canPlaceOrder: rawUser.canPlaceOrder,
            languages: rawUser.languages,
            segments: rawUser.segments,
            legalName: rawUser.legalName,
            defaultLanguage: rawUser.defaultLanguage,
            hasSegments: rawUser.hasSegments,
            username: rawUser.username,
            activeLanguage: rawUser.defaultLanguage,
            membershipDetails: rawUser.membershipDetails,
            address: rawUser.address,
            brief: rawUser.brief,
            contacts: rawUser.contacts,
            activities: rawUser.activities,
            userLanguages: rawUser.userLanguages,
            isVerified: rawUser.isVerified,
          };
          set("user", user);

          if (user.canPlaceOrder) await initiateSocketIo(true, dispatch);

          const permissions = response.data.permissions;
          if (permissions) {
            set("staff-permissions", permissions);
          }

          dispatch(setUserState({ user, state: USER_STATE.LOGGED_IN }));
          dispatch(updateIsAuthenticated(true));

          if (!user.isVerified) {
            window.location.href = "/app/email-verification";
            return;
          }
        } else {
          dispatch(logout(dispatch));
        }

        const membershipTiers = response.data.membershipTiers;
        set("membershipTiers", membershipTiers);

        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.SUCCESS,
            message: response.data.message,
          })
        );
        dispatch(updateXHR({ xhr: XHR_STATE.SUCCESS }));
      } else {
        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.ERROR,
            message: response.data.message,
          })
        );
        dispatch(updateXHR({ xhr: XHR_STATE.FAILED }));
      }
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : error.message;
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message,
        })
      );
      dispatch(updateXHR({ xhr: XHR_STATE.FAILED }));
    });
};

export const logout = (dispatch: Dispatch<any>) => {
  // const url = baseURL.concat("/auth/user/logout");
  dispatch(updateXHR({ xhr: XHR_STATE.REQUESTING }));
  xhr
    .post("/auth/user/logout")
    .then((response) => {
      dispatch(updateXHR({ xhr: XHR_STATE.SUCCESS }));
      if (response.data.success) {
        // set("user", {});
        rm("user");
        set("staff-permissions", undefined);
        rm("membershipTiers");
        rm("token");

        dispatch(updateIsAuthenticated(false));
        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.SUCCESS,
            message: "Logged out successfully",
          })
        );
        window.location.href = "/";
      }
    })
    .catch((err) => {
      dispatch(updateXHR({ xhr: XHR_STATE.FAILED }));
      console.log(err);
    });
};

export const validateAuth = async (dispatch: Dispatch<any>) => {
  const token = get("token");
  const user = get("user");

  if (!token) {
    rm("user");
    rm("token");
    dispatch(
      updateFeedback({
        show: true,
        type: "error",
        message: "Session expired. Please log in again.",
      })
    );

    window.location.href = "/";
    return false;
  }

  if (!user.isVerified && token) {
    window.location.href = "/app/email-verification";
    return false;
  }

  // when an API request is made to the server to verify the user’s authentication (endpoint not ready)

  // try {
  //   const response = await xhr.get("/auth/user/verify", {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });

  //   if (!response.data.success) {
  //     throw new Error(response.data.message || "Session expired.");
  //   }

  //   return true;
  // } catch (error) {
  //   rm("user");
  //   rm("token");
  //   dispatch(
  //     updateFeedback({
  //       show: true,
  //       type: "error",
  //       message: "Session expired. Please log in again.",
  //     })
  //   );
  //   window.location.href = "/";
  //   return false;
  // }
};

export const verifyEmail = (code: string, dispatch: Dispatch<any>) => {
  dispatch(updateXHR({ xhr: XHR_STATE.REQUESTING }));

  xhr
    .post("/user/verify-email", { code }, { headers: xhrHeaders })
    .then((response) => {
      if (response.data.success) {
        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.SUCCESS,
            message: "Email verified successfully!",
          })
        );
        dispatch(updateIsVerified(true));
        window.location.href = `app/${ROUTES.BASE}/`;
        dispatch(updateXHR({ xhr: XHR_STATE.SUCCESS }));
      } else {
        const errorMessage =
          response?.data?.message ||
          "Email verification failed. Please try again.";
        dispatch(
          updateFeedback({
            show: true,
            type: FEEDBACK_VARIANT.ERROR,
            message: errorMessage,
          })
        );
      }
    })
    .catch((err) => {
      const errMessage =
        err.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
      dispatch(
        updateFeedback({
          show: true,
          type: FEEDBACK_VARIANT.ERROR,
          message: errMessage,
        })
      );
      dispatch(updateXHR({ xhr: XHR_STATE.FAILED }));
    });
};
