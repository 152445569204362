import React, { useEffect, useState } from "react";
import * as authHelpers from "./helper";
import * as LoginComponents from "./styledComponents/styledLogin";
import FeedbackAlert from "../extras/Alert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { XHR_STATE } from "../../helpers/xhr";
import { USER_STATE } from "../../redux/slices/Auth";
import { ROUTES } from "../modal/helpers/constants";
import { Link } from "react-router-dom";
import { get } from "lockr";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Typography } from "@mui/material";

const Login = () => {
  const logo = require("../../assets/logo.jpeg");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { feedback, xhr } = useSelector((state: RootState) => state.App);
  const { userState } = useSelector((state: RootState) => state.Auth);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState<boolean>(true);

  const user = get("user");

  useEffect(() => {
    if (userState === USER_STATE.LOGGED_IN && !feedback.show) {
      navigate(`app/${ROUTES.BASE}/`);
    }
  }, [navigate, userState, feedback]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      navigate(`app/${ROUTES.BASE}/`);
    } else {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <LoginComponents.Container>
      <LoginComponents.FeedbackWrapper>
        <FeedbackAlert
          show={feedback.show}
          message={feedback.message}
          type={feedback.type}
          timeout={2000}
        />
      </LoginComponents.FeedbackWrapper>
      <LoginComponents.Form
        onSubmit={(e) =>
          authHelpers.loginSubmitHandler(e, credentials, dispatch)
        }
      >
        <LoginComponents.FormContentWrapper>
          <div style={{ width: "100%", margin: "0px 0px 15px" }}>
            <img src={logo} alt="logo" width={100} />
          </div>

          <Box component="div" width="100%" mb={2} sx={{ textAlign: "left" }}>
            <Typography variant="h4" mb={1}>
              Welcome!
            </Typography>
            <Typography variant="body2" sx={{ color: "#313131d7" }}>
              Login to your Menutender account
            </Typography>
          </Box>

          <Box
            component="div"
            width="100%"
            mb={3}
            sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <LoginComponents.InputWrapper>
              <LoginComponents.InputField
                type="email"
                onChange={(e) => {
                  const lowerCaseEmail = e.target.value;
                  setCredentials({ ...credentials, username: lowerCaseEmail });
                }}
                disabled={xhr === XHR_STATE.REQUESTING}
                placeholder={"Email address"}
                required={true}
              />

              <LoginComponents.Icon>
                <EmailIcon fontSize="small" />
              </LoginComponents.Icon>
            </LoginComponents.InputWrapper>

            <LoginComponents.InputWrapper>
              <LoginComponents.InputField
                type="password"
                required={true}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
                placeholder={"Password"}
                disabled={xhr === XHR_STATE.REQUESTING}
              />

              <LoginComponents.Icon>
                <LockIcon fontSize="small" />
              </LoginComponents.Icon>
            </LoginComponents.InputWrapper>
          </Box>

          <LoginComponents.SubmitButton
            type="submit"
            disabled={xhr === XHR_STATE.REQUESTING}
          >
            {xhr === XHR_STATE.REQUESTING ? "Loading..." : "Login"}
          </LoginComponents.SubmitButton>

          <LoginComponents.Line />
          <LoginComponents.LinksWrapper>
            <Link to="/reset-password">Forgot password?</Link>
          </LoginComponents.LinksWrapper>
        </LoginComponents.FormContentWrapper>
      </LoginComponents.Form>
    </LoginComponents.Container>
  );
};

export default Login;
