import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "./PayloadTypes/AuthPayload";
import { MembershipTier, User } from "../../types/common.types";

export enum USER_STATE {
  LOGGED_OUT,
  LOGGED_IN,
}
export interface AuthState {
  userState: USER_STATE;
  user: User;
  isAuthenticated: boolean;
}
const initialState: AuthState = {
  userState: 0,
  user: {
    currency: { prefix: "", name: "" },
    defaultLanguage: "",
    canPlaceOrder: false,
    legalName: "",
    languages: [],
    segments: [],
    hasSegments: false,
    username: "",
    userLanguages: [],
    activeLanguage: "",
    address: "",
    brief: "",
    contacts: [],
    activities: [],
    membershipDetails: {
      id: "",
      name: "",
      tier: MembershipTier.BASIC,
      price: 0,
      description: [],
      limits: {
        staff: 0,
        product: 0,
        segment: 0,
        order: false,
      },
    },
    isVerified: false,
  },
  isAuthenticated: false,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserState: (state, action: PayloadAction<UserState>) => {
      return {
        ...state,
        userState: action.payload.state,
        user: action.payload.user,
        isAuthenticated: action.payload.state === USER_STATE.LOGGED_IN,
      };
    },
    updateActiveLanguage: (state, action: PayloadAction<string>) => {
      state.user.activeLanguage = action.payload;

      return state;
    },
    updateMembershipTier: (state, action: PayloadAction<MembershipTier>) => {
      state.user.membershipDetails.tier = action.payload;

      return state;
    },

    updateIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },

    updateIsVerified: (state, action: PayloadAction<boolean>) => {
      state.user.isVerified = action.payload;
    },
  },
});

export const {
  setUserState,
  updateActiveLanguage,
  updateMembershipTier,
  updateIsAuthenticated,
  updateIsVerified,
} = AuthSlice.actions;

export default AuthSlice.reducer;
