import * as authHelpers from "./helper";
import React, { useRef, useState } from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import CustomButton from "../common/CustomButton";
import { useDispatch } from "react-redux";

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  padding: 1rem;
`;

const CodeInputContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const CodeInput = styled.input`
  width: 70px;
  height: 70px;
  border: 2px solid #000;
  border-radius: 20px;
  text-align: center;
  font-size: 1.5rem;
  background-color: #fff;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;

  &:focus {
    border-color: #6e151e;
    box-shadow: 0 0 5px rgba(110, 21, 30, 0.5);
  }
`;

const EmailVerification = () => {
  const [code, setCode] = useState<string[]>(Array(6).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const dispatch = useDispatch();

  const handleInputChange = (value: string, index: number) => {
    if (!/^\d$/.test(value)) return; // Ensure only a single digit
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < code.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace") {
      const newCode = [...code];
      if (!code[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      newCode[index] = "";
      setCode(newCode);
    }
  };

  const handleVerify = () => {
    const verificationCode = code.join("");
    authHelpers.verifyEmail(verificationCode, dispatch);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData("Text");
    if (!/^\d{6}$/.test(pastedData)) return;

    const newCode = pastedData.split("");
    setCode(newCode);

    newCode.forEach((digit, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index]!.value = digit;
      }
    });

    inputRefs.current[newCode.length - 1]?.focus();
  };

  const isSubmitDisabled = code.some((digit) => digit === "");

  return (
    <VerificationContainer>
      <Typography variant="h3" mb={2}>
        Please verify your email
      </Typography>
      <Typography textAlign="center" mb={4} fontFamily="500">
        Enter the verification code <br />
        we sent to your email
      </Typography>
      <CodeInputContainer>
        {code.map((value, index) => (
          <CodeInput
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            value={value}
            onChange={(e) => handleInputChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={index === 0 ? handlePaste : undefined}
            maxLength={1}
            aria-label={`Verification code input ${index + 1}`}
          />
        ))}
      </CodeInputContainer>

      <CustomButton
        size="large"
        height="47px"
        sx={{
          maxWidth: "175px",
          mt: "35px",
          cursor: "pointer",
        }}
        onClick={handleVerify}
        disabled={isSubmitDisabled}
      >
        Verify
      </CustomButton>
    </VerificationContainer>
  );
};

export default EmailVerification;
